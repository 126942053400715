<script>
  export let svg;
  export let withRotation = false;
</script>

<div class={withRotation ? "rotate" : ""}>
  {@html svg}
</div>

<style>
  div {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    width: var(--svg-square-size, 100%);
    height: var(--svg-square-size, 100%);
    border-radius: var(--borderRadius, 0);
    overflow: hidden;
  }
  div :global(> svg) {
    width: var(--svg-square-size, 100%);
    height: var(--svg-square-size, 100%);
  }
  div.rotate {
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
